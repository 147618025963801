














































import { Component, Vue, Prop } from 'vue-property-decorator'
import { v4 as uuidv4 } from 'uuid'

@Component({
  name: 'Textarea',
  // inheritAttrs: false,
})
export default class BaseTextarea extends Vue {
  showPassword = false

  @Prop({ default: () => uuidv4() })
  id!: string

  @Prop()
  value!: unknown

  @Prop({ default: false })
  isShakeError!: boolean

  @Prop()
  label!: string

  @Prop({ default: 5 })
  rows!: number

  @Prop()
  classWrap!: string

  @Prop({ default: false })
  labelRequered!: boolean

  @Prop({ default: false })
  group!: boolean

  @Prop()
  errorText!: string

  @Prop({ default: false })
  hasError!: boolean

  @Prop({ default: 0 })
  minLength!: number
}
