














































import { Component, Vue, Prop, Ref } from 'vue-property-decorator'
import BaseButton from '@/components/base/BaseButton.vue'
import BaseIcon from '@/components/base/BaseIcon.vue'
import FormGroup from '@/components/forms/FormGroup.vue'
import { namespace } from 'vuex-class'
const UserStore = namespace('UserStore')
import Field from '@/components/forms/Field.vue'
import BaseTextarea from '@/components/forms/BaseTextarea.vue'
import { PremiumUpdateInfoModel } from '../models/PremiumModel'
import PremiumService from '../PremiumService'
import { UserModel } from '@/core/models/UserModel'

@Component({
    name: 'PartnerForm',

    components: {
        BaseButton,
        BaseIcon,
        FormGroup,
        Field,
        BaseTextarea
    },
})
export default class ProfilePage extends Vue {

    loading = false

    @Prop({})
    partnerId!: string

    description = ""

    @UserStore.Getter
    public currentUser!: UserModel

    isMobile!: boolean
    logoImage!: any
    backgroundImage!: any
    logo = ""
    background = ""

    inputLogoImage(e: any) {
        var src = URL.createObjectURL(e.target.files[0]);
        console.log("file>>>", e.target.files[0])
        this.logoImage = e.target.files[0]
        this.logo = src
    }

    inputBackgroundImage(e: any) {
        var src = URL.createObjectURL(e.target.files[0]);
        this.backgroundImage = e.target.files[0]
        this.background = src
    }

    async loadData() {
        const response = await PremiumService.premiumGetPartner(this.currentUser.requisites.id)
        console.log("ID id >>>", this.currentUser.requisites.id)
        console.log("response >>>", response)
        this.logo = response.logo_image
        this.background = response.background_image
    }

    created() {
        this.loadData()
    }

    @Ref('inputLogoImage') readonly inputLogoImageRef!: HTMLInputElement

    @Ref('inputBackgroundImage') readonly inputBackgroundImageRef!: HTMLInputElement

    openWindowLogo(): void {
        const element: HTMLElement = this.$refs.inputLogoImage as HTMLElement
        if (element) {
            element.click()
        }
    }

    async submit() {
        const payload: PremiumUpdateInfoModel = {
            description: this.description,
            logoImage: this.logoImage,
            backgroundImage: this.backgroundImage
        }
        const response = PremiumService.premiumUpdateInfo(payload)
        console.log("response >>>>", response)
    }

    updateDescription(e: any) {
        console.log(e.target.value)
    }

    get logoPath() {
        if (this.inputLogoImageRef) {
            return this.inputLogoImageRef.value
        }
        return ''
    }



    openWindowBackground(): void {
        const element: HTMLElement = this.$refs.inputBackgroundImage as HTMLElement
        if (element) {
            element.click()
        }
    }

}

